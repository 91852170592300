import ProjectModal from "../ProjectModal/ProjectModal";
import { isMyPortfolio } from "../../shared/utils";
import "./Projects.css";
import { memo } from "react";

const Projects = ({ project }) => {
  return (
    <div className="Projectpage" id="projects">
      <div className="projectsCard">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="projectHeader">
              <h3>{project.name}</h3>
              <ProjectModal
                name={project.name}
                description={project.description}
                link={project.link}
                technologies={project.technologies}
                backend={project.repos[0].backendRepo}
                frontend={project.repos[0].frontendRepo}
                images={project.images}
                repo={project.repos[0]}
              />
            </div>
            <h6
              style={{
                textAlign: "left",
                padding: "0 10px",
                marginTop: "-8px",
              }}
            >
              {project.type}
            </h6>
            <div
              className="Projects"
              style={{
                backgroundImage: `url(${project.picture})`,
                // backgroundPosition: "center",
                backgroundPosition: isMyPortfolio(project.name) ? "center" : "",
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
              }}
            >
              {/* Commented out is a link to each projects details page if I want to use this*/}
              {/* <Link to={`/projects/${props.id}`}>See more</Link> */}
            </div>
          </div>
          {/* <div className="flip-card-back">
                <h2>Details</h2>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default memo(Projects);
