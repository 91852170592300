import Skills from "./Skills";
import { useMemo } from "react";
import { getSkills } from "../../services/skills";

export default function AllSkills() {
  const skillData = useMemo(getSkills, []);

  return (
    <div id="majorskilldiv">
      <div id="skillsdiv">
        {skillData.map((skill, idx) => (
          <Skills key={idx} skill={skill} />
        ))}
      </div>
    </div>
  );
}
