import "./Skills.css";

const Skills = ({ skill }) => {
  const styles = constructionStyles();

  const reactClassName = (skillName) => {
    if (skillName === "ReactNative") {
      return "imgSpinner";
    }

    if (skillName === "React.js") {
      return "imgSpinnerBackwards";
    }

    return "";
  };

  return (
    <div id="allskills">
      <div id="skillscomp">
        <div id="skills">
          <div id="skill-box">
            <div id="name">
              <p id="namep">{skill.skillName}</p>
            </div>
            {skill.skillName && skill.skillUrl !== "" ? (
              <div id="imgbox">
                <p style={styles.skillName}>{skill.skillName}</p>
                <img
                  id="skillimg"
                  src={skill.skillUrl}
                  alt="skill"
                  className={reactClassName(skill.skillName)}
                  width={80}
                  height={80}
                />
              </div>
            ) : (
              <h3 id="skillImg" style={styles.noImg}>
                🚧 Under Construction... More skills coming Soon 👷🏼
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function constructionStyles() {
  return {
    noImg: {
      width: "100%",
      margin: "12px 0",
    },
    skillName: {
      marginTop: -26,
      color: "transparent",
    },
  };
}

export default Skills;
