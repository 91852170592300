import FormRequest from "../FormRequest/FormRequest";
import "./Footer.css";

export default function Footer() {
  return (
    <div id="footerDiv">
      <div id="footerThings">
        <div id="technology">
          <h3>Resume 📥</h3>
          <a
            href="/Jared-Long-resume.docx"
            target="_blank"
            download
            className="icons"
            aria-label="resume"
          >
            <h1>
              <i className="fas fa-file-word fa-lg" />
            </h1>
          </a>
        </div>
        <div id="form">
          <FormRequest />
        </div>
        <div
          id="socialIcons"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h3>Let's Connect 👨‍💻 </h3>
          <div>
            <h1 className="icons">
              <a
                href="https://www.linkedin.com/in/jaredlong-95/"
                target="_blank"
                rel="noreferrer"
                aria-label="linkedin"
              >
                <i className="fab fa-linkedin fa-lg"></i>
              </a>
            </h1>
            <h1 className="icons">
              <a
                href="https://dev.to/jlong4223"
                target="_blank"
                rel="noreferrer"
                aria-label="dev.to blogs"
              >
                <img
                  src="https://cdn1.iconfinder.com/data/icons/logos-and-brands-3/512/84_Dev_logo_logos-512.png"
                  style={{ width: "50px", paddingBottom: "5px" }}
                  width={50}
                  height={53}
                  alt="dev.to"
                />
              </a>
            </h1>
            <h1 className="icons">
              <a
                href="https://github.com/jlong4223"
                target="_blank"
                rel="noreferrer"
                aria-label="github account"
              >
                <i className="fab fa-github-square fa-lg"></i>
              </a>
            </h1>
          </div>
        </div>
      </div>
      <footer className="Footer">
        Copyright &copy; Jared's Portfolio {new Date().getFullYear()} All Rights
        Reserved
      </footer>
    </div>
  );
}
