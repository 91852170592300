import "./Profile.css";

const Profile = () => {
  return (
    <div id="hugeDiv">
      <div id="bigDiv">
        <header id="profileHeader">
          <div id="profileDiv">
            <div id="profilepicdiv"></div>
            <div id="nameDiv">
              <h1>Hi, I'm Jared Long</h1>
              <h3>"Saw"ftware Engineer</h3>
              <h4>Austin, TX</h4>
              <h4>
                <a className="profileCont" href="mailto:jlong4223@gmail.com">
                  jlong4223@gmail.com
                </a>
              </h4>
              <h4>
                {/* Google Voice number */}
                {/* <a className="profileCont" href="tel:512-763-0244">
                  (512)-763-0244
                </a> */}
              </h4>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Profile;
