export function InfoContainer({ children, screenWidth }) {
  const styles = getStyles({ screenWidth });

  return (
    <div style={styles.infoContainer}>
      <div style={styles.info}>{children}</div>
    </div>
  );
}

function getStyles({ screenWidth }) {
  const smallScreen = 750;

  return {
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: screenWidth > smallScreen ? "20%" : "100%",
    },
    info: {
      display: "flex",
      flexDirection: screenWidth <= smallScreen ? "row" : "column",
      justifyContent: "space-between",
    },
  };
}
