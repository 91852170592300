import { useRef } from "react";
import emailjs from "emailjs-com";
import "./FormRequest.css";

const FormRequest = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tzaxcgk",
        "template_jg1oe3g",
        form.current,
        "user_eeCsjSc1kWAxSJiyi3ve9"
      )
      .then(
        (result) => {
          form.current.reset();
          if (result.status === 200) {
            alert("Message Sent - Jared will be in touch soon");
            return;
          }
          alert("Sorry for the inconvenience as this is under construction!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="container" id="messageme">
      <div>
        <form ref={form} className="form-horizontal" onSubmit={sendEmail}>
          <fieldset className="fieldset">
            <legend>Let's Chat</legend>
            <div className="form-group">
              <div id="envelope" className="col-sm-12">
                <h3>
                  <i className="fas fa-user-circle"></i>
                </h3>
                <input type="text" name="from_name" placeholder="Name" />
              </div>
            </div>
            <div className="form-group">
              <div id="envelope" className="col-sm-12">
                <h3>
                  <i className="fas fa-envelope-square"></i>
                </h3>
                <input type="email" name="from_name" placeholder="Email" />
              </div>
            </div>
            <div className="form-group">
              <div id="pencil" className="col-sm-12">
                <h3>
                  <i className="fas fa-pen-square"></i>
                </h3>
                <textarea name="message" placeholder="Your message..." />
              </div>
            </div>
            <button
              id="submit"
              className="btn btn-default"
              type="submit"
              aria-label="submit-message"
              style={{ margin: "5px 0" }}
            >
              Send Message
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default FormRequest;
