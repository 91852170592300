import { Component } from "react";
import { sendErrorEmail } from "../shared/utils";

export const withErrorBoundary = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, errorMsg: "" };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      const errorDetails = { error, errorInfo };
      this.setState({ errorMsg: errorDetails });
      console.log("ERROR HERE", error);
      console.log("ERROR INFO HERE", errorInfo);
    }

    render() {
      if (this.state.hasError) {
        return (
          <div>
            <h1>Something went wrong.</h1>
            <h2>Jared Long has been notified</h2>
            {sendErrorEmail(this.state.errorMsg)}
          </div>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};
