import { useState, useEffect, useRef } from "react";
import "./Header.css";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <header
      id="mainHead"
      className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
      ref={ref}
    >
      <header id="myHeader" className="sticky-inner">
        <div id="navbar">
          <div>
            <a href="#bigAbout">About</a>
            <a href="#blogs">Blogs</a>
            {/* <a href="#allskills">Skills</a> */}
          </div>
          <div>
            <a href="#projects">Projects</a>
            <a href="#messageme">Contact</a>
          </div>
          {/* <Link to="/">Resume</Link> */}
          {/* <a href="">Blog</a> */}
        </div>
      </header>
    </header>
  );
};

export default Header;
