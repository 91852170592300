import BlogsModal from "./BlogsModal";
import { memo } from "react";

function Blogs({ blog }) {
  return (
    <div className="Projectpage">
      <div className="projectsCard">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="projectHeader">
              <h5>{blog.title}</h5>
              <BlogsModal blog={blog} />
            </div>
            <div
              className="Projects"
              style={{
                backgroundImage: `url(${blog.cover_image})`,
                backgroundPosition: "center",
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Blogs);
