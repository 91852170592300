import axios from "axios";
import { useQuery } from "react-query";
import { BLOGIDS } from "../shared/constants";
import { sendErrorEmail } from "../shared/utils";

export const blogsUrl = "https://dev.to/api/articles?username=jlong4223";
export const viewsUrl =
  "https://scraping-api.jl-project.workers.dev/blog-views";

export const getBlogs = async () => {
  const { data } = await axios.get(blogsUrl);
  const { views } = await getBlogViews();

  const updatedBlogData = data.map((blog) => {
    blog.description = BLOGIDS[blog.id].desc;
    blog.views = views[BLOGIDS[blog.id].viewsTitle] ?? 0;
    return blog;
  });

  return updatedBlogData;
};

export const getBlogViews = async () => {
  try {
    const { data } = await axios.get(
      // "http://127.0.0.1:8787/blog-views"
      viewsUrl
    );
    return data;
  } catch (err) {
    sendErrorEmail(err.response.data);
    return { views: null };
  }
};

export const useBlogs = () => {
  const { data: blogs, isLoading, isFetching } = useQuery("blogs", getBlogs);
  return { blogs, isLoading, isFetching };
};
