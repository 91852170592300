import emailjs from "emailjs-com";

export const isMyPortfolio = (projectName) => {
  return projectName === "My Portfolio";
};

export const getBaseUrl = () => {
  const url = window.location.href;
  const baseUrl = url.indexOf("localhost") > -1 ? "localhost" : "prod";
  const isLocal = baseUrl === "localhost";
  return { isLocal };
};

export const sendErrorEmail = (error) => {
  const { isLocal } = getBaseUrl();

  if (isLocal) {
    console.log("🚀 ~ file: utils.js:10 ~ getBaseUrl ~ url:", error);
    return;
  }

  emailjs.send(
    "service_tzaxcgk",
    "template_u5r4jhc",
    {
      from_name: "Jared",
      to_name: "Jared",
      message: JSON.stringify(error),
    },
    "user_eeCsjSc1kWAxSJiyi3ve9"
  );
};
