import { useState, memo, useEffect } from "react";
import Modal from "react-modal";
import { customStyles } from "../../shared/modalStyles";
import { InfoContainer } from "./InfoContainer";

Modal.setAppElement("#root");

const BlogsModal = ({ blog }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const styles = getStyles({ screenWidth });
  // if there is not a year for the blog, set it to the current year

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <button
        className="btn btn-default"
        onClick={() => setModalIsOpen(true)}
        aria-label="blog-info-button"
      >
        {" "}
        <i className="fas fa-info-circle fa-lg"></i>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <h2>{blog.title}</h2>
        <h6>{blog.description}</h6>
        <h5>
          <a
            href={blog.url}
            rel="noreferrer"
            target="_blank"
            aria-label="blog-url"
          >
            Check out the article here
          </a>
        </h5>
        <div style={styles.allInfo}>
          <InfoContainer screenWidth={screenWidth}>
            <h5>Published:</h5>
            <h5>{blog.readable_publish_date}</h5>
          </InfoContainer>
          <InfoContainer screenWidth={screenWidth}>
            <h5>Views:</h5>
            <h5>{new Intl.NumberFormat().format(blog.views)}</h5>
          </InfoContainer>
          <InfoContainer screenWidth={screenWidth}>
            <h5>Bookmarks:</h5>
            <h5>{blog.public_reactions_count}</h5>
          </InfoContainer>
          <InfoContainer screenWidth={screenWidth}>
            <h5>Reading Time:</h5>
            <h5>{blog.reading_time_minutes} minutes</h5>
          </InfoContainer>
        </div>

        <button
          className="btn btn-default closebtn"
          onClick={() => setModalIsOpen(false)}
          aria-label="close-button"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

function getStyles() {
  return {
    allInfo: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingBottom: "10px",
      paddingTop: "10px",
      alignItems: "center",
      justifyContent: "space-around",
    },
  };
}

export default memo(BlogsModal);
