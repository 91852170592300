export const getProjects = () => [
  {
    _id: "5fe16c93a3df37421514cc18",
    name: "Review Your Pour",
    description:
      "An app used to input types of coffees as well as review inputed coffees.",
    picture: "https://i.imgur.com/f98Fxsf.png",
    link: "https://pour-review.herokuapp.com/",
    technologies:
      "Node.js, Express.js, MongoDB, Bootstrap, Javascript, EJS(embedded javascript)",
    reviews: [
      {
        rating: 5,
        _id: "5fe16c93a3df37421514cc19",
        review: "Amazing second project",
        createdAt: "2020-12-22T03:48:35.643Z",
        updatedAt: "2020-12-22T03:48:35.643Z",
      },
    ],
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/review-beans",
        backendRepo: "https://github.com/jlong4223/review-beans",
      },
    ],
    createdAt: "2020-12-22T03:48:35.644Z",
    updatedAt: "2020-12-22T03:48:35.644Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "5fe16db34cdcab426da5be2f",
    name: "Pedals",
    description:
      "A MERN stack app that allows you to see local bikes for rent as well as list your own bike. The user can also browse some local trails.",
    picture: "https://i.imgur.com/5cHdGiv.png",
    link: "https://pedals-frontend.herokuapp.com/",
    technologies:
      "MongoDB, Express.js, React.js, Node.js, JsonWebToken auth, Mongoose.js, CSS3, Bootstrap, TrailRunProject API",
    reviews: [
      {
        rating: 5,
        _id: "5fe16db34cdcab426da5be30",
        review: "Amazing third project",
        createdAt: "2020-12-22T03:53:23.394Z",
        updatedAt: "2020-12-22T03:53:23.394Z",
      },
    ],
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/pedals-front-end",
        backendRepo: "https://github.com/jlong4223/pedals-back-end",
      },
    ],
    createdAt: "2020-12-22T03:53:23.394Z",
    updatedAt: "2020-12-22T03:53:23.394Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "5ffe284bae075600174a0645",
    name: "BreweryAdvisor",
    description:
      "This is an application where users can view breweries based on the city of their choosing. Users can post to a city, edit typos, and delete breweries.",
    picture: "https://i.imgur.com/137Cn03.jpg",
    link: "https://brewery-advisor-app.herokuapp.com/",
    technologies: "Ruby on Rails, React.js, PostgreSQL",
    reviews: [
      {
        rating: 5,
        _id: "5ffe284bae075600174a0646",
        review: "cool stuff",
        createdAt: "2021-01-12T22:52:59.948Z",
        updatedAt: "2021-01-12T22:52:59.948Z",
      },
    ],
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/brewery_advisor_client",
        backendRepo: "https://github.com/jlong4223/brewery_advisor_api",
      },
    ],
    createdAt: "2021-01-12T22:52:59.948Z",
    updatedAt: "2021-01-12T22:52:59.948Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },

  {
    _id: "6005fb9163cee7001766b6a9",
    name: "Gears N' Beans 💻",
    description:
      "VENM Stack App. This is a website for a company that sells coffee products and offers bike services. The user can view the products, services and add/delete reviews. *This is not a real company.",
    picture: "https://i.imgur.com/9k3pDZm.png",
    link: "https://gears-beans-client.herokuapp.com/",
    technologies: "Vue.js, Express.js, Node.js, MongoDB, Google Maps",
    reviews: [
      {
        rating: 5,
        _id: "6005fb9163cee7001766b6aa",
        review: "cool stuff",
        createdAt: "2021-01-18T21:20:17.600Z",
        updatedAt: "2021-01-18T21:20:17.600Z",
      },
    ],
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/gears-n-beans-client",
        backendRepo: "https://github.com/jlong4223/gears-beans-API",
      },
    ],
    createdAt: "2021-01-18T21:20:17.601Z",
    updatedAt: "2021-01-18T21:20:17.601Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "604a415af3f5dd0017ef9f8e",
    name: "Port Chaveriat",
    description: "A Full-Stack app used to add and track events",
    picture: "https://i.imgur.com/ZOZcviz.png",
    link: "https://port-chaveriat.herokuapp.com/",
    technologies:
      "React.js, Node.js, Express.js, MySQL, Javascript, OAuth2, Nodemailer, ClearDB, Heroku, Bcrypt, Google Maps",
    reviews: [
      {
        rating: 5,
        _id: "604a415bf3f5dd0017ef9f8f",
        review: "Amazing project",
        createdAt: "2021-03-11T16:12:11.024Z",
        updatedAt: "2021-03-11T16:12:11.024Z",
      },
    ],
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/react-pc-client",
        backendRepo: "https://github.com/jlong4223/node-mysql-pc-API",
      },
    ],
    createdAt: "2021-03-11T16:12:11.025Z",
    updatedAt: "2021-03-11T16:12:11.025Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "6058d6268b4ff1001724db07",
    name: "ATX Animals",
    description:
      "A Full-Stack app that represents a mock animal shelter and includes protected admin routes, adoptable animals, as well as a task management page for employees.",
    picture: "https://i.imgur.com/G5qQe2i.png",
    link: "https://atx-animals.herokuapp.com/",
    technologies:
      "React.js, Node.js, Express.js, Mocha.js, Chai.js, MongoDB, Javascript, OAuth2, Nodemailer, Heroku, Bcrypt, JsonWebToken, Bootstrap, CSS3",
    reviews: [
      {
        rating: 5,
        _id: "6058d6268b4ff1001724db08",
        review: "Amazing project",
        createdAt: "2021-03-22T17:38:46.327Z",
        updatedAt: "2021-03-22T17:38:46.327Z",
      },
    ],
    repos: [
      {
        _id: "6058d6268b4ff1001724db09",
        frontendRepo: "https://github.com/jlong4223/react-atx-animals",
        backendRepo: "https://github.com/jlong4223/atx-animals-api",
        createdAt: "2021-03-22T17:38:46.327Z",
        updatedAt: "2021-03-22T17:38:46.327Z",
      },
    ],
    createdAt: "2021-03-22T17:38:46.328Z",
    updatedAt: "2021-03-22T17:38:46.328Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "6099f432b738650017bc23c3",
    name: "S & S Quotes",
    description:
      "An app that lists quotes by Michael Scott and Ron Swanson for users to view and/or favorite.",
    picture: "https://i.imgur.com/dcFeGCM.png",
    link: "https://office-client-vue.herokuapp.com/",
    technologies:
      "Vue.js, Ruby, Ruby on Rails, PostgreSQL, Bulma, JsonWebToken, Express.js, Yarn, Git",
    reviews: [
      {
        rating: 5,
        _id: "6099f432b738650017bc23c4",
        review: "Amazing project",
        createdAt: "2021-05-11T03:04:18.590Z",
        updatedAt: "2021-05-11T03:04:18.590Z",
      },
    ],
    repos: [
      {
        _id: "6099f432b738650017bc23c5",
        frontendRepo: "https://github.com/jlong4223/the-office-client",
        backendRepo: "https://github.com/jlong4223/rails-office-api",
        createdAt: "2021-05-11T03:04:18.590Z",
        updatedAt: "2021-05-11T03:04:18.590Z",
      },
    ],
    createdAt: "2021-05-11T03:04:18.590Z",
    updatedAt: "2021-05-11T03:04:18.590Z",
    __v: 0,
    shouldShowOnPortfolio: false,
    type: "",
  },
  {
    _id: "617987d20d4bf58832715758",
    name: "Gears N' Beans📱",
    description:
      "This is an ecommerce mobile app for the Gears N Beans coffee-bike shop company. A user can add items to their cart, create an account, add & edit reviews,  and view company info. ",
    repos: [
      {
        frontendRepo: "https://github.com/jlong4223/RN-gearsNbeans",
        backendRepo: "https://github.com/jlong4223/gears-beans-API",
      },
    ],
    technologies:
      "React Native, NativeBase, Redux, Node.js, XCode, Android Studio, Google Maps, Token Auth, Git, ESLint, Lodash, MongoDB, Axios, Async-Storage",
    images: [
      "https://i.imgur.com/zS7a4M8.png",
      "https://i.imgur.com/KKkvAaM.png",
      "https://i.imgur.com/nrveLIM.png",
      "https://i.imgur.com/5wqlaRg.png",
      "https://i.imgur.com/cFRsamk.png",
      "https://i.imgur.com/WcmmZxF.png",
      "https://i.imgur.com/OZ4XNqk.png",
      "https://i.imgur.com/TTyucZ0.png",
    ],
    link: "no-link",
    "picture-og": "https://i.imgur.com/zS7a4M8.png",
    picture: "https://i.imgur.com/wdYCuhx.png?1",
    reviews: [],
    shouldShowOnPortfolio: true,
    type: "Mobile App",
  },
  {
    _id: "630a7fbb94dd6a7740ac7dfg",
    name: "New Girl API ⛮",
    description:
      "This is an API for all information related to the TV Series New Girl.",
    link: "https://rapidapi.com/jlong4223/api/new-girl",
    picture: "https://i.imgur.com/xmatoXt.png",
    repos: [
      {
        repo: "https://github.com/jlong4223/worker-new-girl",
      },
    ],
    technologies:
      "Cloudflare Workers, RapidAPI, TypeScript, Node.js, Vitest, FaunaDB Utils, FaunaDB, Github CI",
    reviews: [],
    shouldShowOnPortfolio: true,
    type: "Backend API",
  },
  {
    _id: "630a7fbb94dd6a7740ac7dfe",
    name: "My Portfolio 💻",
    description: "This is my portfolio that you are viewing now.",
    link: "https://www.jaredlong.dev/",
    picture: "https://i.imgur.com/cvBjoCn.png",
    repos: [
      {
        repo: "https://github.com/jlong4223",
      },
    ],
    technologies:
      "React.js, React Query, Axios, Cloudflare Pages, Cloudflare Workers, Node.js, MongoDB, CSS3, SASS, Email.js, Jest, Github CI",
    reviews: [],
    shouldShowOnPortfolio: true,
    type: "Frontend & Backend API",
  },
  {
    _id: "619eaa85267fc0706825e40a",
    name: "staged-linter 📦",
    description: "This NPM package will run ESLint only on staged files. ",
    picture: "https://authy.com/wp-content/uploads/npm-logo.png",
    link: "https://www.npmjs.com/package/staged-linter",
    technologies: "Node.js, Git, ESLint, NPM, shelljs",
    repos: [
      {
        repo: "https://github.com/jlong4223/staged-linter",
      },
    ],
    reviews: [],
    shouldShowOnPortfolio: true,
    type: "npm package",
  },
  {
    _id: "630a7fbb94dd6a7740ac7dff",
    name: "FaunaDB Utils  📦",
    description:
      "This is an NPM utility package for setting up a FaunaDB client and working with FaunaDB queries. This was created to simplfy the syntax for interacting with data stored in FaunaDB.",
    link: "https://www.npmjs.com/package/@gearsnbeans/faunadb-utils",
    picture: "https://authy.com/wp-content/uploads/npm-logo.png",
    repos: [
      {
        repo: "https://github.com/GearsN-Beans/faunadb-utils",
      },
    ],
    technologies: "FaunaDB, Node.js, Vite, Vitest, TypeScript, NPM, Github CI",
    reviews: [],
    shouldShowOnPortfolio: true,
    type: "npm package",
  },
];
