export const BLOGIDS = {
  650356: {
    viewsTitle: "nodemailerViews",
    desc: "Nodemailer is a Node.js module that allows users to send messages directly to your email. This article is a guide to help you connect your Node.js server to your GMail account by using OAuth2 in addition to creating a React.js Form to send an email.",
  },
  1174293: {
    viewsTitle: "launchAgentsViews",
    desc: "In this article, I will guide you through setting up a LaunchAgent that will automatically run a JavaScript project to update & commit to GitHub. This is a fun way to keep that Github 'grass' green.",
  },
};
