export const getSkills = () => [
  {
    _id: "62ac95bfd3bc8240e7a1343a",
    skillName: "ReactNative",
    skillUrl:
      "https://icongr.am/devicon/react-original.svg?size=100&color=currentColor",
  },
  {
    _id: "5ffe63cc7ab2ebbd58206158",
    skillName: "JavaScript",
    skillUrl:
      "https://icongr.am/devicon/javascript-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:06:52.835Z",
    updatedAt: "2021-01-13T03:06:52.835Z",
    __v: 0,
  },
  {
    _id: "5ffe69f07ab2ebbd58206168",
    skillName: "TypeScript",
    skillUrl:
      "https://icongr.am/devicon/typescript-original.svg?size=128&color=currentColor",
    createdAt: "2021-01-13T03:28:48.157Z",
    updatedAt: "2021-01-13T03:28:48.157Z",
    __v: 0,
  },

  {
    _id: "5ffe68497ab2ebbd58206163",
    skillName: "node.js",
    skillUrl:
      "https://icongr.am/devicon/nodejs-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:26:01.972Z",
    updatedAt: "2021-01-13T03:26:01.972Z",
    __v: 0,
  },

  {
    _id: "5ffe66bc7ab2ebbd5820615a",
    skillName: "MongoDB",
    skillUrl:
      "https://icongr.am/devicon/mongodb-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:19:24.658Z",
    updatedAt: "2021-01-13T03:19:24.658Z",
    __v: 0,
  },
  {
    _id: "5ffe66bc7ab2ebbd5820615a",
    skillName: "Cloudflare",
    skillUrl:
      "https://cdn-icons-png.flaticon.com/512/5969/5969044.png?size=100",
    createdAt: "2021-01-13T03:19:24.658Z",
    updatedAt: "2021-01-13T03:19:24.658Z",
    __v: 0,
  },
  {
    _id: "5ffe66ef7ab2ebbd5820615b",
    skillName: "NPM",
    skillUrl:
      "https://icongr.am/devicon/npm-original-wordmark.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:20:15.048Z",
    updatedAt: "2021-01-13T03:20:15.048Z",
    __v: 0,
  },
  {
    _id: "5ffe670b7ab2ebbd5820615c",
    skillName: "jQuery",
    skillUrl:
      "https://icongr.am/devicon/jquery-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:20:43.598Z",
    updatedAt: "2021-01-13T03:20:43.598Z",
    __v: 0,
  },
  {
    _id: "5ffe67b67ab2ebbd58206160",
    skillName: "Express.js",
    skillUrl:
      "https://icongr.am/devicon/express-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:23:34.660Z",
    updatedAt: "2021-01-13T03:23:34.660Z",
    __v: 0,
  },
  {
    _id: "5ffe65067ab2ebbd58206159",
    skillName: "React.js",
    skillUrl:
      "https://icongr.am/devicon/react-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:12:06.762Z",
    updatedAt: "2021-01-13T03:12:06.762Z",
    __v: 0,
  },
  {
    _id: "5ffe67357ab2ebbd5820615d",
    skillName: "Heroku",
    skillUrl:
      "https://icongr.am/devicon/heroku-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:21:25.009Z",
    updatedAt: "2021-01-13T03:21:25.009Z",
    __v: 0,
  },
  {
    _id: "5ffe67507ab2ebbd5820615e",
    skillName: "HTML5",
    skillUrl:
      "https://icongr.am/devicon/html5-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:21:52.703Z",
    updatedAt: "2021-01-13T03:21:52.703Z",
    __v: 0,
  },
  {
    _id: "5ffe677a7ab2ebbd5820615f",
    skillName: "CSS3",
    skillUrl:
      "https://icongr.am/devicon/css3-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:22:34.000Z",
    updatedAt: "2021-01-13T03:22:34.000Z",
    __v: 0,
  },

  {
    _id: "5ffe67f77ab2ebbd58206162",
    skillName: "Git",
    skillUrl:
      "https://icongr.am/devicon/git-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:24:39.675Z",
    updatedAt: "2021-01-13T03:24:39.675Z",
    __v: 0,
  },

  {
    _id: "5ffe67e07ab2ebbd58206161",
    skillName: "GitHub",
    skillUrl:
      "https://icongr.am/devicon/github-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:24:16.476Z",
    updatedAt: "2021-01-13T03:24:16.476Z",
    __v: 0,
  },
  {
    _id: "5ffe67e07ab2ebbd58206161",
    skillName: "Gitlab",
    skillUrl:
      "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/144_Gitlab_logo_logos-512.png?size=100",
    createdAt: "2021-01-13T03:24:16.476Z",
    updatedAt: "2021-01-13T03:24:16.476Z",
    __v: 0,
  },
  {
    _id: "5ffe68847ab2ebbd58206165",
    skillName: "Ruby",
    skillUrl:
      "https://icongr.am/devicon/ruby-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:27:00.470Z",
    updatedAt: "2021-01-13T03:27:00.470Z",
    __v: 0,
  },
  {
    _id: "5ffe68697ab2ebbd58206164",
    skillName: "Rails",
    skillUrl: "https://icongr.am/devicon/rails-plain.svg?size=100&color=e13333",
    createdAt: "2021-01-13T03:26:33.789Z",
    updatedAt: "2021-01-13T03:26:33.789Z",
    __v: 0,
  },
  {
    _id: "5ffe689e7ab2ebbd58206166",
    skillName: "PostgreSQL",
    skillUrl:
      "https://icongr.am/devicon/postgresql-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:27:26.803Z",
    updatedAt: "2021-01-13T03:27:26.803Z",
    __v: 0,
  },
  {
    _id: "5ffe68f07ab2ebbd58206168",
    skillName: "Sass",
    skillUrl:
      "https://icongr.am/devicon/sass-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:28:48.157Z",
    updatedAt: "2021-01-13T03:28:48.157Z",
    __v: 0,
  },
  {
    _id: "5ffe69277ab2ebbd58206169",
    skillName: "VScode",
    skillUrl:
      "https://icongr.am/devicon/visualstudio-plain.svg?size=100&color=currentColor",
    createdAt: "2021-01-13T03:29:43.879Z",
    updatedAt: "2021-01-13T03:29:43.879Z",
    __v: 0,
  },
  {
    _id: "60064cdf43670b00174304fb",
    skillName: "Vue.js",
    skillUrl:
      "https://icongr.am/devicon/vuejs-original.svg?size=128&color=currentColor",
    createdAt: "2021-01-19T03:07:11.478Z",
    updatedAt: "2021-01-19T03:07:11.478Z",
    __v: 0,
  },
  {
    _id: "600663d7120aae0017627071",
    skillName: "Postman",
    skillUrl: "https://miro.medium.com/max/512/1*fVBL9mtLJmHIH6YpU7WvHQ.png",
    createdAt: "2021-01-19T04:45:11.443Z",
    updatedAt: "2021-01-19T04:45:11.443Z",
    __v: 0,
  },
  {
    _id: "60137622c330f90017b3f604",
    skillName: "MySQL",
    skillUrl:
      "https://icongr.am/devicon/mysql-original.svg?size=100&color=currentColor",
    createdAt: "2021-01-29T02:42:42.344Z",
    updatedAt: "2021-01-29T02:42:42.344Z",
    __v: 0,
  },
  {
    _id: "60259aa007073a0017c17954",
    skillName: "AngularJS",
    skillUrl:
      "https://icongr.am/devicon/angularjs-original.svg?size=100&color=currentColor",
    createdAt: "2021-02-11T20:59:12.280Z",
    updatedAt: "2021-02-11T20:59:12.280Z",
    __v: 0,
  },
  {
    _id: "6058d4aa8b4ff1001724db06",
    skillName: "Mocha.js",
    skillUrl:
      "https://icongr.am/devicon/mocha-plain.svg?size=100&color=currentColor",
    createdAt: "2021-03-22T17:32:26.910Z",
    updatedAt: "2021-03-22T17:32:26.910Z",
    __v: 0,
  },
  {
    _id: "605bbe0e3a4559001705503b",
    skillName: "Yarn",
    skillUrl:
      "https://icongr.am/devicon/yarn-original.svg?size=100&color=currentColor",
    createdAt: "2021-03-24T22:32:46.201Z",
    updatedAt: "2021-03-24T22:32:46.201Z",
    __v: 0,
  },
  {
    _id: "60f196306279533d5415ffea",
    skillName: "Redux",
    skillUrl: "https://cdn.iconscout.com/icon/free/png-512/redux-283024.png",
  },
  {
    _id: "618be0eaec6f6829c86b5ffa",
    skillName: "Jest",
    skillUrl:
      "https://user-images.githubusercontent.com/10525473/50372432-95dcd880-0611-11e9-9432-58de9be26b3b.png",
    // "https://camo.githubusercontent.com/62089edec0ee40bb26b3bf5f973b14d7f8e4b4e942f115cde5b9a5f9c0ca3382/687474703a2f2f7365656b6c6f676f2e636f6d2f696d616765732f4a2f6a6573742d6c6f676f2d463939303145424246372d7365656b6c6f676f2e636f6d2e706e67",
  },
  {
    _id: "618be1949be7035e0e7f6646",
    skillName: "MaterialUI",
    skillUrl: "https://mui.com/static/logo.png",
  },
  {
    _id: "618be2869be7035e0e7f6648",
    skillName: "NativeBase",
    skillUrl: "https://docs.nativebase.io/img/nativebaselogo.svg",
  },
  {
    _id: "620e84b9aee3dec1fac680c6",
    skillName: "Serverless",
    skillUrl: "https://www.svgrepo.com/download/315522/serverless.svg",
  },
  {
    _id: "620e841aaee3dec1fac680c5",
    skillName: "AWSLambda",
    skillUrl:
      "https://images.squarespace-cdn.com/content/v1/51814c87e4b0c1fda9c1fc50/1528473310893-RH0HG7R5C0QURMFQJBSU/600px-AWS_Lambda_logo.svg.png",
  },
];
