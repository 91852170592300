import "./App.css";
import About from "./components/About/About";
import Header from "./components/Header/Header";
import AllSkills from "./components/Skills/AllSkills";
import Footer from "./components/Footer/Footer";
import Profile from "./components/Profile/Profile";
import AllBlogs from "./components/Blogs/AllBlogs";
import { withErrorBoundary } from "./services/withErrorBoundary";
import ProjectsContainer from "./components/Projects/ProjectsContainer";
import { memo } from "react";

const App = function () {
  // throw new Error("App error"); // uncomment to test error boundary
  return (
    <div className="App">
      <Profile />
      <Header />
      <div id="aboutSkills">
        <About />
        <AllSkills />
      </div>
      <AllBlogs />
      <ProjectsContainer />
      <Footer />
    </div>
  );
};

export default memo(withErrorBoundary(App));
