import Projects from "./Projects";
import { useMemo } from "react";
import { getProjects } from "../../services/allProjects";

export default function ProjectsContainer() {
  const styles = useMemo(getStyles, []);
  const projectData = useMemo(getProjects, []);

  const filteredProjects = projectData?.filter(
    (project) => project.shouldShowOnPortfolio
  );

  return (
    <div style={styles.projectContainer}>
      <div className="projectDiv reversedDiv">
        {projectData ? (
          filteredProjects.map((project, idx) => (
            <Projects key={idx} id={project._id} project={project} />
          ))
        ) : (
          <h1>Project section coming soon 💻 </h1>
        )}
      </div>
    </div>
  );
}

function getStyles() {
  return {
    projectContainer: {
      marginTop: "40px",
    },
  };
}
