import { useState } from "react";
import Modal from "react-modal";
import { isMyPortfolio } from "../../shared/utils";
import { customStyles } from "../../shared/modalStyles";

Modal.setAppElement("#root");

const ProjectModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const styles = getStyles();

  return (
    <div>
      <button
        className="btn btn-default"
        onClick={() => setModalIsOpen(true)}
        aria-label="project-info-button"
      >
        <i className="fas fa-info-circle fa-lg"></i>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <h1>{props.name}</h1>
        <h5>{props.description}</h5>
        <h3>Technologies Used:</h3>
        <h5>{props.technologies}</h5>
        {props.link !== "no-link" && !isMyPortfolio(props.name) && (
          <h4>
            <a
              href={props.link}
              rel="noreferrer"
              target="_blank"
              aria-label="project code"
            >
              Check out the deployed app
            </a>
          </h4>
        )}
        {props.images && (
          <>
            <h4>Images</h4>
            <div style={styles.imgContainer}>
              {props.images.map((image, idx) => (
                <img style={styles.img} src={image} key={idx} alt="project" />
              ))}
            </div>
          </>
        )}
        {props.repo.repo && !isMyPortfolio(props.name) && (
          <h4>
            <a
              href={props.repo.repo}
              rel="noreferrer"
              target="_blank"
              aria-label="project code"
            >
              Repository
            </a>
          </h4>
        )}
        {props.backend && props.frontend && (
          <>
            <h4>
              <a
                href={props.backend}
                rel="noreferrer"
                target="_blank"
                aria-label="project backend code"
              >
                Backend Repo
              </a>
            </h4>
            <h4>
              <a
                href={props.frontend}
                rel="noreferrer"
                target="_blank"
                aria-label="project frontend code"
              >
                Frontend Repo
              </a>
            </h4>
          </>
        )}
        <button
          className="btn btn-default closebtn"
          onClick={() => setModalIsOpen(false)}
          aria-label="close-modal-button"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

function getStyles() {
  return {
    imgContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    img: {
      height: "400px",
      margin: "5px",
    },
  };
}

export default ProjectModal;
