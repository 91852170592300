import Blogs from "./Blogs";
import { useBlogs } from "../../services/blogsAndHooks";

export default function AllBlogs() {
  const { blogs, isLoading: isBlogsLoading } = useBlogs();

  return (
    <div id="blogs" className="projectDiv">
      {!isBlogsLoading ? (
        blogs?.map((blog) => <Blogs blog={blog} key={`blog:${blog.id}`} />)
      ) : (
        <h1>Blog section coming soon 💻 </h1>
      )}
    </div>
  );
}
